import { useQuery, useQueryClient } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

import Button from "../../components/Buttons/Button/Button";
import Buttons from "../../components/Buttons/Buttons/Buttons";
import { FormInput, FormSelect } from "../../components/Form/FormInputs/FormInput";
import FormWrapper from "../../components/Form/FormWrapper";
import MainCard from "../../components/layout/MainCard/MainCard";
import LoadingWrapper from "../../components/Loading/LoadingWrapper";
import __ from "../../config/translations/serbian";
import useAPI from "../../hooks/useAPI";
import { GlobalQueries } from "../../hooks/useRefreshQuery";
import Sitemap from "../../routes/sitemap";
import EmptyPage from "../Empty/EmptyPage";
import { currencyFormat } from "helpers/functions";
import Typography from "@mui/material/Typography";

import CheckoutTable from "./CheckoutTable/CheckoutTable";
import MobileCheckout from "./MobileCheckout/MobileChekout";

import formScss from "../../components/Form/FormWrapper.module.scss";
import scss from "./CheckoutPage.module.scss";
import { Box } from "@mui/material";

const CheckoutPage = () => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const api = useAPI();

    // Load all products in the cart
    const { data, isLoading } = useQuery([GlobalQueries.Checkout], () => api.list(`/checkout`));
    const items = data?.items ?? [];
    const cost = data?.summary ?? null;

    // Load available addresses
    const { data: addressData, addressesAreLoading } = useQuery([GlobalQueries.Profile.Addresses], () => api.get(`/checkout/ddl/shipping_addresses`));
    const addresses = addressData ?? [];

    // Load the current company
    const { data: customer, customerIsLoading } = useQuery([GlobalQueries.Profile.MyProfile, GlobalQueries.Profile.CompanyProfile], () => api.get(`/customer/verify`));

    // Selected addresses
    const [form, setForm] = useState({ shipping: null, billing: null, note: "" });

    const [acceptMessage, setAcceptMessage] = useState(null);
    const acceptTerms = useRef("1");

    useEffect(
        () =>
            setForm((form) => ({
                ...form,
                shipping: form.shipping ?? (addresses.length > 0 ? addresses[0].id_company_address ?? null : null),
                billing: form.billing ?? (addresses.length > 0 ? addresses[0].id_company_address ?? null : null),
            })),
        [addresses]
    );

    // Get the shipping address
    const billingAddress = addresses.find((address) => address.type === "head_office");
    const shippingAddress = form.shipping ? addresses.find((address) => address.id_company_address === 1 * form.shipping) : null;

    /**
     * Check data validation. Handling order save. Reload cart after save.
     */
    const [loading, setLoading] = useState(false);
    const submitHandler = async () => {
        setLoading(true);
        // Make sure we have billing address
        if (!billingAddress) {
            toast.warning(__.Checkout.Messages.PleaseSelectBillingAddress);
            return;
        }

        // Make sure we have shipping address
        if (!shippingAddress) {
            toast.warning(__.Checkout.Messages.PleaseSelectShippingAddress);
            return;
        }

        // Build the post data
        const data = {
            id_company_billing: billingAddress.id_company,
            id_company_address_billing: billingAddress.id_company_address,
            company_name_billing: billingAddress.company_name,
            pib_billing: billingAddress.pib,
            maticni_broj_billing: billingAddress.maticni_broj,
            first_name_billing: billingAddress.first_name,
            last_name_billing: billingAddress.last_name,
            phone_billing: billingAddress.phone,
            email_billing: billingAddress.email,
            address_billing: billingAddress.address,
            object_number_billing: billingAddress.object_number,
            floor_billing: billingAddress.floor,
            apartment_number_billing: billingAddress.apartment_number,
            id_town_billing: billingAddress.id_town,
            town_name_billing: billingAddress.town_name,
            zip_code_billing: billingAddress.zip_code,
            id_municipality_billing: billingAddress.id_municipality,
            municipality_name_billing: billingAddress.municipality_name,
            id_country_billing: billingAddress.id_country,
            country_name_billing: billingAddress.country_name,
            note_billing: billingAddress.note,

            id_company_shipping: shippingAddress.id_company,
            id_company_address_shipping: shippingAddress.id_company_address,
            company_name_shipping: shippingAddress.company_name,
            pib_shipping: shippingAddress.pib,
            maticni_broj_shipping: shippingAddress.maticni_broj,
            first_name_shipping: shippingAddress.first_name,
            last_name_shipping: shippingAddress.last_name,
            phone_shipping: shippingAddress.phone,
            email_shipping: shippingAddress.email,
            address_shipping: shippingAddress.address,
            object_number_shipping: shippingAddress.object_number,
            floor_shipping: shippingAddress.floor,
            apartment_number_shipping: shippingAddress.apartment_number,
            id_town_shipping: shippingAddress.id_town,
            town_name_shipping: shippingAddress.town_name,
            zip_code_shipping: shippingAddress.zip_code,
            id_municipality_shipping: shippingAddress.id_municipality,
            municipality_name_shipping: shippingAddress.municipality_name,
            id_country_shipping: shippingAddress.id_country,
            country_name_shipping: shippingAddress.country_name,
            note_shipping: shippingAddress.note,

            delivery_method: "delivery_to_address",
            delivery_method_options: [],

            payment_method: "pay_on_transfer_order",
            payment_method_options: [],

            promo_code: null,
            promo_code_options: [],

            note: form.note,
        };

        api.post(`/checkout/one-page`, data)
            .then((response) => {
                console.log(response);
                if (response) {
                    toast.success(__.Checkout.CheckoutCompletedSuccessfully);
                    queryClient.invalidateQueries(GlobalQueries.Checkout).catch();
                    queryClient.invalidateQueries(GlobalQueries.Orders).catch();
                    queryClient.invalidateQueries(GlobalQueries.Cart).catch();
                    navigate(Sitemap.cart.success + "/" + response?.order?.slug);
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.warn(error);
                toast.error(__.Messages.UnknownError);
                setLoading(false);
            });
    };
    useEffect(() => {
        if (form.accept) {
            setAcceptMessage(false);
        }
    }, [form.accept]);

    // Show empty page
    if (!isLoading && items.length === 0) {
        return <EmptyPage message={__.EmptyPage.Templates.Cart} />;
    }

    const percentage = Math.round((cost?.totals?.subtotal / cost?.options?.delivery?.free_delivery?.amount) * 100);
    const lineWidth = percentage > 100 ? "100%" : `${percentage}%`;
    const circleLeft = `calc(${lineWidth} - 3rem)`;

    return (
        <LoadingWrapper isLoading={isLoading}>
            <MainCard>
                <div className="page-container">
                    <div className="page-content">
                        <h4 className="h-underline bold">Vaša korpa</h4>
                        {cost && (
                            <div className={scss.wrapper}>
                                <FormWrapper className={`row ${scss.forms}`}>
                                    <div className="col-xl-8">
                                        {/*<h6 className={`underline bold ${scss.title}`}>{__.Checkout.Sections.Cart.Title}:</h6>*/}
                                        <div className={`${scss.cart} ${scss.section}`}>
                                            <div className="mobile-hidden">
                                                <CheckoutTable items={items} showImage={false} showActions={false} />
                                            </div>
                                            <div className="desktop-hidden">
                                                <MobileCheckout items={items} showImage={true} showActions={false} />
                                            </div>
                                        </div>

                                        <div className={`flex p row ${scss.prices}`}>
                                            {/* Labels */}
                                            <div className="no-padding col-6">
                                                <div>{__.Checkout.Sections.PriceBreakdown.BaseAmount}:</div>
                                                <div>{__.Checkout.Sections.PriceBreakdown.RebateAmount}:</div>
                                                <div>{__.Checkout.Sections.PriceBreakdown.DiscountAmount}:</div>
                                                <div>{__.Checkout.Sections.PriceBreakdown.SubTotal}:</div>
                                                <div>{__.Checkout.Sections.PriceBreakdown.VATAmount}:</div>
                                                <div>{__.Checkout.Sections.PriceBreakdown.ShippingAmount}:</div>
                                            </div>

                                            {/* Amounts */}
                                            <div className={`${scss.priceBreakdown} no-padding col-6`}>
                                                <div>{currencyFormat(cost?.totals?.with_out_vat, cost?.currency)}</div>
                                                <div>-{currencyFormat(cost?.totals?.rebate_amount, cost?.currency)}</div>
                                                <div>-{currencyFormat(Number(cost?.totals?.items_discount_amount) + Number(cost?.totals?.cart_discount_amount), cost?.currency)}</div>
                                                <div>{currencyFormat(cost?.totals?.subtotal, cost?.currency)}</div>
                                                <div>{currencyFormat(cost?.totals?.vat, cost?.currency)}</div>
                                                <div>{currencyFormat(cost?.totals?.delivery_amount, cost?.currency)}</div>
                                            </div>
                                            <div className="flex-between pv" style={{ padding: "1rem 0" }}>
                                                <span className="text-bold">{__.Checkout.Sections.PriceBreakdown.Total}:</span>
                                                <span className="text-bold">{currencyFormat(cost.totals.total, cost.currency)}</span>
                                            </div>
                                            {/* bar */}

                                            {cost?.options?.delivery?.free_delivery?.amount && (
                                                <div style={{ padding: "0", width: "50%", marginLeft: "auto" }}>
                                                    <Box
                                                        sx={{
                                                            width: "100%",
                                                            position: "relative",
                                                            "&:after": {
                                                                position: "absolute",
                                                                content: "''",
                                                                top: "50%",
                                                                transform: "translateY(-50%)",
                                                                height: "0.125rem",
                                                                background: "var(--croonus-lightGreen)",
                                                                width: lineWidth,
                                                                zIndex: "-1",
                                                            },
                                                            "&:before": {
                                                                position: "absolute",
                                                                content: "''",
                                                                top: "50%",
                                                                transform: "translateY(-50%)",
                                                                height: "0.125rem",
                                                                background: "var(--croonus-lightGray)",
                                                                width: "100%",
                                                                zIndex: "-2",
                                                            },
                                                        }}
                                                    >
                                                        <Box
                                                            sx={{
                                                                fontSize: "0.688rem",
                                                                color: "var(--primary-color)",
                                                                fontWeight: "600",
                                                                width: "3rem",
                                                                height: "3rem",
                                                                borderRadius: "50%",
                                                                backgroundColor: "#fff",
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                border: "0.125rem solid var(--primary-color)",
                                                                position: "relative",
                                                                left: circleLeft,
                                                            }}
                                                        >
                                                            {cost?.totals?.subtotal > cost?.options?.delivery?.free_delivery?.amount
                                                                ? 100
                                                                : Math.round((Number(cost?.totals?.subtotal) * 100) / Number(cost?.options?.delivery?.free_delivery?.amount))}{" "}
                                                            %
                                                        </Box>
                                                    </Box>

                                                    {!(cost?.totals?.subtotal > cost?.options?.delivery?.free_delivery?.amount) ? (
                                                        <Typography variant="" sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                            Do besplatne dostave nedostaje još{" "}
                                                            <span style={{ fontWeight: "600", margin: "0 0.25rem" }}>
                                                                {currencyFormat(cost?.options?.delivery?.free_delivery?.amount - cost?.totals?.subtotal)}
                                                            </span>
                                                            .
                                                        </Typography>
                                                    ) : (
                                                        <Typography variant="" sx={{ color: "var(--croonus-lightGreen)", display: "flex", justifyContent: "center" }}>
                                                            Ostvarili ste <span style={{ fontWeight: "600", margin: "0 0.25rem" }}>besplatnu</span> dostavu.
                                                        </Typography>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className="col-xl-4">
                                        {/* Customer info */}
                                        <h6 className={`underline bold ${scss.title}`}>{__.Checkout.Sections.CustomerInfo.Title}:</h6>
                                        <div className={`pl ${scss.formSection}`}>
                                            <p className="m-0">
                                                {__.Checkout.Sections.CustomerInfo.InfoTakenFromProfile}: <span className="text-bold">{customer?.company?.name}</span>
                                            </p>
                                            <p className="m-0">
                                                {__.Checkout.Sections.CustomerInfo.Edit.ToModifyYourInfoEditYour1}{" "}
                                                <Link to={Sitemap.profile.company_profile} className="text-bold">
                                                    {__.Checkout.Sections.CustomerInfo.Edit.ToModifyYourInfoEditYour2}
                                                </Link>
                                                .
                                            </p>
                                        </div>

                                        {/* Shipment */}
                                        <h6 className={`underline bold ${scss.title}`}>{__.Checkout.Sections.Shipment.Title}:</h6>
                                        <div className={`pl ${scss.formSection}`}>
                                            <FormSelect field="shipping" data={form} setData={setForm} options={addresses} />

                                            {/*<FormSelect*/}
                                            {/*    field="billing"*/}
                                            {/*    data={selectedAddresses}*/}
                                            {/*    setData={setSelectedAddresses}*/}
                                            {/*    options={addresses.filter(address => ["head_office", "billing"].includes(address.type)).map(address => [address.id, address.name])} />*/}

                                            {/*<div className={scss["form-check"] + " form-check"}>*/}
                                            {/*    <input*/}
                                            {/*        type="checkbox"*/}
                                            {/*        id="option1"*/}
                                            {/*        checked={checkbox === "option1"}*/}
                                            {/*        onChange={changeCheckbox}*/}
                                            {/*        className={scss["form-check-input"] + " form-check-input"}*/}
                                            {/*    />*/}
                                            {/*    <label htmlFor="option1" className={scss["form-text"] + " form-check-label"}>*/}
                                            {/*        {__.Checkout.Sections.Shipment.Options.SameAsInvoiceAddress}*/}
                                            {/*    </label>*/}
                                            {/*</div>*/}
                                            {/*<div className={scss["form-check"] + " form-check"}>*/}
                                            {/*    <input*/}
                                            {/*        type="checkbox"*/}
                                            {/*        id="option2"*/}
                                            {/*        checked={checkbox === "option2"}*/}
                                            {/*        onChange={changeCheckbox}*/}
                                            {/*        className={scss["form-check-input"] + " form-check-input"}*/}
                                            {/*    />*/}
                                            {/*    <label htmlFor="option2" className={scss["form-text"] + " form-check-label"}>*/}
                                            {/*        {__.Checkout.Sections.Shipment.Options.AnotherAddress}*/}
                                            {/*    </label>*/}
                                            {/*</div>*/}

                                            {/*{shippingData?.shipping_address && checkbox === "option2" && (*/}
                                            {/*    <p className={scss["form-text"]}>*/}
                                            {/*        {__.Checkout.Sections.Shipment.ChosenAddress}:{" "}*/}
                                            {/*        <span className="text-bold">*/}
                                            {/*            {[shippingData?.shipping_address, shippingData?.shipping_city, shippingData?.shipping_country].join(", ")}*/}
                                            {/*        </span>.*/}
                                            {/*    </p>*/}
                                            {/*)}*/}
                                        </div>

                                        <h6 className={`underline bold ${scss.title}`}>{__.Checkout.Sections.Note.Title}:</h6>
                                        <div className={`${formScss.input} pl ${scss.formSection}`}>
                                            <FormInput field="note" data={form} placeholder={__.Checkout.Sections.Note.Placeholder} setData={setForm} type="textarea" rows="5" />
                                        </div>
                                    </div>
                                </FormWrapper>

                                <Buttons align="center" className="col-12">
                                    <Button label={__.Cart.Continue} onClick={() => navigate("/pocetna")} className={scss.continue} />
                                    <Button loading={loading} label={__.Checkout.Actions.ConfirmThePurchase} onClick={() => submitHandler()} className={scss.checkout} />
                                </Buttons>
                            </div>
                        )}
                    </div>
                </div>
            </MainCard>
        </LoadingWrapper>
    );
};

export default CheckoutPage;
